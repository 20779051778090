import React from 'react'
import { Chip, ChipProps, styled } from '@mui/material'

type FilterMarkerProps = ChipProps & {
  label: string;
  value?: string;
}

const FilterChip = styled(({ label, value, ...props }: FilterMarkerProps) => {
  if(value) {
    label = `${label}: ${value}`
  }
  return <Chip { ...props } label={label} />
})(({ theme }) => ({
  fontSize: 12,
  height: 24,
  '.MuiChip-label': {
    padding: theme.spacing(0, 1)
  },
  '.MuiSvgIcon-root': {
    width: 12,
    height: 12,
  }
}))

export default FilterChip
