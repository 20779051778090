import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, IconButtonProps, styled } from '@mui/material'

const SearchButton = styled((props: IconButtonProps) => {
  return (
    <IconButton {...props}>
      <SearchIcon color='inherit' />
    </IconButton>
  )
})(({ theme: { palette, shape }}) => ({
  backgroundColor: palette.primary.main,
  borderRadius: shape.borderRadius,
  '&, &:hover': {
    color: '#fff',
  },
  '&:hover:not(:disabled)': {
    backgroundColor: palette.primary.dark,
  },
  '&:disabled': {
    backgroundColor: 'transparent',
    color: palette.primary.main,
    // opacity: 0.7,
    svg: {
      position: 'relative',
      top: '1px',
    }
  }
}))

export default SearchButton
