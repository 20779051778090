import React from 'react'
import { Box, BoxProps, styled } from '@mui/material'

const CardDrawerHeader = styled(({ className, ...props }: BoxProps) => (
  <Box { ...props } className={className + ' CardDrawer__Header'} />
))(() => ({
  '.MuiTab-root': {
    fontSize: 13,
    fontWeight: 500,
  },
  '.MuiTabs-root, .MuiTab-root': {
    minHeight: 38
  }
}))

export default CardDrawerHeader
