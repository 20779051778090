export const API_NAME = 'Tach'
export const API_HOST = process.env.API_HOST as string
export const SUPER_TOKEN_API_HOST = process.env.SUPER_TOKEN_API_HOST as string
export const IMAGE_CDN_HOST = process.env.IMAGE_CDN_HOST as string
export const VIDEO_CDN_HOST = process.env.VIDEO_CDN_HOST as string
export const STREAM_LIVE_HOST = process.env.STREAM_LIVE_HOST as string
export const STREAM_ARCHIVE_HOST = process.env.STREAM_ARCHIVE_HOST as string
export const API_BASE_PATH = '/auth'
export const DEFAULT_PER_PAGE = 25
export const PER_PAGE_LIST = [ 10, 15, 20, 25, 50, 100 ]
export const IMAGE_URL = (size: number, fileName: string) => (
  `${IMAGE_CDN_HOST}/images/${size}/${fileName}`
)
