import React from 'react'
import { Box, CircularProgress, CircularProgressProps, styled } from '@mui/material'

type LoadingProps = CircularProgressProps & {
  position?: 'fixed' | 'absolute' | 'static';
  backdrop?: 'black' | 'white' | 'none';
}

const Loading = styled(
  ({ className, color = 'primary', size = 120, thickness = 1, ...props }: LoadingProps) => {
    return (
      <Box className={className}>
        <CircularProgress {...props} color={color} size={size} thickness={thickness} />
      </Box>
    )
  }
)(
  ({ theme, position = 'fixed', backdrop = 'black' }) => {
    let background: string

    switch (backdrop) {
      case 'black':
        background = 'rgba(0, 0, 0, 0.3)'
        break
      case 'white':
        background = 'rgba(255, 255, 255, 0.3)'
        break
      default:
        background = 'none'
    }

    return {
      zIndex: theme.zIndex.modal + 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: position,
      display: 'flex',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      background,
    }
  }
)

export default Loading
