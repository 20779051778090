import React from 'react'
import { Avatar, AvatarProps, CircularProgress, styled } from '@mui/material'
import { UserProfile } from '@src/types/models'
import { AnonymousIcon } from '@components/icons'
import { IMAGE_URL } from '@src/config'

type UserAvatarProps = AvatarProps & {
  profile?: UserProfile | null;
  loading?: boolean;
  size?: number;
}

const UserAvatar = styled(({ profile, loading, ...props }: UserAvatarProps) => {

  if (loading) {
    return (
      <Avatar {...props}>
        <CircularProgress color='inherit' size={16} />
      </Avatar>
    )
  }

  if(!profile) {
    return (
      <Avatar {...props}>
        <AnonymousIcon />
      </Avatar>
    )
  }

  if(profile.picture) {
    return <Avatar {...props} src={IMAGE_URL(50, profile.picture)} />
  }

  if(profile?.firstname || profile.lastname) {
    return (
      <Avatar {...props}>
        {(profile.firstname[0] || '') + (profile.lastname[0] || '')}
      </Avatar>
    )
  }

  return (
    <Avatar {...props}>
      <AnonymousIcon />
    </Avatar>
  )
})(({ theme, size = 24 }) => ({
  backgroundColor: theme.palette.primary.main,
  textTransform: 'uppercase',
  color: 'white',
  fontSize: 12,
  height: size,
  width: size,
  'svg:not(.MuiCircularProgress-svg)': {
    height: '75%',
    width: '75%',
  }
}))

export default UserAvatar
