import React, { DetailedHTMLProps, FormHTMLAttributes } from 'react'
import { Box, BoxProps, styled } from '@mui/material'
import { StyledComponent } from '@mui/styles'

type SearchFormProps = Omit<BoxProps, 'onSubmit'>
  & DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & {
  fullWidth?: boolean;
}

const SearchForm: StyledComponent<SearchFormProps> = styled(
  ({ className, fullWidth, ...props }: SearchFormProps) => {
    return <Box {...props} component="form" className={className + ' AppSearchForm'} />
  }
)(({ theme, fullWidth }) => ({
  width: fullWidth ? '100%' : 'max(50%, 600px)',
  display: 'inline-flex',
  alignItems: 'center',
  '> .AddFilterButton': {
    marginLeft: theme.spacing(3)
  }
}))

export default SearchForm
