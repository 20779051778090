import React, { useEffect, useRef, useState } from 'react'
import { Box, BoxProps, styled } from '@mui/material'
import Hls from 'hls.js'
import VideoControl from '@components/UserContentCard/UserContentVideo/VideoControl'
import { SourceType } from '@components/UserContentCard/UserContentVideo/types'

type UserContentVideoProps = Omit<BoxProps, 'onPlay' | 'onPause'> & {
  onPlay?: (video: HTMLVideoElement) => void;
  onPause?: (video: HTMLVideoElement) => void;
  src: string;
  videoId?: string;
  autoPlay?: boolean;
  live?: boolean;
}

const UserContentVideo = styled(
  ({ src, videoId, autoPlay, live, onPlay, onPause, children, ...props }: UserContentVideoProps) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const playerRef = useRef<HTMLVideoElement | null>(null)
    const hlsRef = useRef<Hls>()

    const defaultSource: SourceType = {
      url: src,
      label: 'Default'
    }

    const [ sources, setSources ] = useState<SourceType[]>([
      defaultSource
    ])

    const [ currentSource, setCurrentSource ] = useState<number>(0)
    const [ currentTime, setCurrentTime ] = useState<number>(0)
    const [ fullScreen, setFullScreen ] = useState<boolean>(false)
    const [ play, setPlay ] = useState<boolean>(false)

    useEffect(() => {
      const video = playerRef.current!
      let hls = hlsRef.current!

      const _initPlayer = () => {
        if (hls) {
          // debug('hls.destroy')
          hls.destroy()
        }

        hls = hlsRef.current = new Hls({
          debug: false,
        })

        hls.on(Hls.Events.MANIFEST_PARSED, (e, { levels }) => {
          setSources(levels.map<SourceType>(level => ({ url: level.url[ 0 ], label: `${level.height}p` })))
          setCurrentSource(levels?.length - 1)
          hls.startLevel = levels?.length - 1
        })

        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          hls.loadSource(src)
        })

        hls.on(Hls.Events.LEVEL_LOADED, () => {
          // debug({ levels: hls.levels })
        })

        hls.on(Hls.Events.LEVEL_UPDATED, () => {
          // debug({ currentLevel: hls.currentLevel })
        })

        hls.on(Hls.Events.ERROR, (e, data) => {
          // debug({ hlsErr: { e, data }})
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                hls.startLoad()
                break
              case Hls.ErrorTypes.MEDIA_ERROR:
                hls.recoverMediaError()
                break
              default:
                _initPlayer()
                break
            }
          }
        })

        hls.attachMedia(video)
      }

      if (Hls.isSupported()) {
        _initPlayer()
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = src
        video.addEventListener('canplay', () => {
          // video.play()
        })
      }

      if (autoPlay) {
        video.addEventListener('loadedmetadata', () => {
          video.play().then()
        })
      }

      return () => {
        if(!video.paused) {
          video.pause()
        }

        if (hls) {
          hls.destroy()
        }
      }
    }, [ src, autoPlay ])

    useEffect(() => {
      const handleFullScreenChange = () => {
        setFullScreen((prev) => !prev)
      }

      document.addEventListener('fullscreenchange', handleFullScreenChange)

      return () => {
        document.removeEventListener('fullscreenchange', handleFullScreenChange)
      }
    }, [])

    useEffect(() => {
      const video = playerRef.current!
      if(play && video.paused) {
        video.play().then(() => onPlay && onPlay(video)).catch()
      } else {
        video.pause()
        onPause && onPause(video)
      }
    }, [ play ])

    const handleTimeUpdate = () => {
      setCurrentTime(playerRef?.current?.currentTime || 0)
    }

    const handleFullScreen = async () => {
      if (document?.pictureInPictureElement) {
        await document.exitPictureInPicture()
      }

      if (document?.fullscreenElement) {
        await document.exitFullscreen()
      } else {
        await containerRef.current!.requestFullscreen()
      }
    }
    
    const handleChangeSource = async (index: number) => {
      if (!playerRef?.current || currentSource === index) {
        return
      }
      
      setCurrentSource(index)

      if (hlsRef?.current) {
        const hls = hlsRef.current
        hls.startLevel = index
        hls.loadSource(sources[index].url)
        hls.startLoad()
      } else {
        playerRef.current.setAttribute('src', sources[index].url)
      }

      const tmpCurrentTime = currentTime
      setCurrentTime(tmpCurrentTime)

      if (playerRef?.current) {
        playerRef.current.currentTime = tmpCurrentTime
        await playerRef.current.play()
      }
    }

    const seekHandle = (time: number) => {
      if(playerRef.current) {
        playerRef.current.currentTime = time
      }
    }

    return (
      <Box {...props} ref={containerRef}>
        <video
          onPause={() => setPlay(false)}
          onPlay={() => setPlay(true)}
          onTimeUpdate={handleTimeUpdate}
          preload="metadata"
          ref={playerRef}
          id={videoId}
        />
        <VideoControl
          timeline={!playerRef?.current?.duration ? 0 : (currentTime * 100) / (playerRef?.current?.duration as number)}
          duration={playerRef?.current?.duration as number}
          currentTime={playerRef?.current?.currentTime || 0}
          onSourceChange={handleChangeSource}
          onPause={() => setPlay(false)}
          onPlay={() => setPlay(true)}
          onFullScreen={handleFullScreen}
          currentSource={currentSource}
          fullScreen={fullScreen}
          onSeek={seekHandle}
          sources={sources}
          play={play}
        />
        {children}
      </Box>
    )
  }
)(() => ({
  background: 'black',
  overflow: 'hidden',
  borderRadius: 8,
  aspectRatio: '16/9',
  width: '100%',
  position: 'relative',
  video: {
    width: '100%',
    height: '100%',
  }
}))

export default UserContentVideo
