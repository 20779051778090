import React, { useMemo, useRef, useState } from 'react'
import { Box, BoxProps, IconButton, styled } from '@mui/material'
import FilterItems from '@components/UserContentCard/FilterItems'
import FilterMoreButton from '@components/FilterMoreButton'
import ClearFiltersButton from '@components/ClearFiltersButton'
import DropDownPopover from '@components/DropDownPopover'
import { DropDownCloseIcon } from '@components/icons'
import { ContentFilterItemListType, ContentFilterType } from '@src/types/usercontent'
import { DeleteOutline } from '@mui/icons-material'
import { red } from '@mui/material/colors'

export type FilterListProps = BoxProps & {
  onFilterChange: (filter: ContentFilterType) => void;
  onClearFilters: () => void;
  clearButtonUseIcon?: boolean;
  maxShownFilters?: number;
  filter: ContentFilterType;
}

const FilterList = styled(
  ({
    filter,
    onFilterChange,
    onClearFilters,
    maxShownFilters = 4,
    clearButtonUseIcon,
    ...props
  }: FilterListProps) => {
    const [ filterListPopoverAnchorEl, setFilterListPopoverAnchorEl ]
      = useState<HTMLElement | null>(null)
    const filterListRef = useRef<HTMLElement>(null)

    const filterList: ContentFilterItemListType = useMemo(() => {
      const result: ContentFilterItemListType = []
      Object.entries(filter).forEach(([ type, data ]) => {
        (data || []).forEach(value => {
          result.push({ type: type as keyof ContentFilterType, value })
        })
      })
      return result
    }, [ filter ])

    return (
      <>
        <Box { ...props }>
          <Box ref={filterListRef} sx={{ display: 'flex' }}>
            <FilterItems
              items={filterList.slice(0, maxShownFilters)}
              onFilterChange={onFilterChange}
              filter={filter}
            />
            <FilterMoreButton
              onClick={(e) => setFilterListPopoverAnchorEl(e.currentTarget)}
              hidden={filterList.length <= maxShownFilters}
              open={!!filterListPopoverAnchorEl}
              sx={{ ml: 1.5 }}
            >
              +{filterList.length - maxShownFilters}
            </FilterMoreButton>
          </Box>
          {clearButtonUseIcon ? (
            <IconButton onClick={onClearFilters} sx={{ color: red[400], p: '1px', ml: 1 }} title='Очистить все'>
              <DeleteOutline />
            </IconButton>
          ) : (
            <ClearFiltersButton onClick={onClearFilters}>
              Очистить все
            </ClearFiltersButton>
          )}
        </Box>
        <DropDownPopover
          open={!!filterListPopoverAnchorEl && filterList.length > maxShownFilters}
          onClose={() => setFilterListPopoverAnchorEl(null)}
          anchorEl={filterListPopoverAnchorEl}
          alignX='right'
          sx={(theme) => ({
            '.MuiPaper-root': {
              padding: theme.spacing(2, 5, 0.5, 0.5),
              width: filterListRef.current?.offsetWidth || '50%',
              minHeight: 56,
              mt: 1
            }
          })}
        >
          <IconButton
            onClick={() => setFilterListPopoverAnchorEl(null)}
            sx={{ position: 'absolute', top: 0.5, right: 0.5 }}
          >
            <DropDownCloseIcon />
          </IconButton>
          <FilterItems
            items={filterList.slice(maxShownFilters)}
            onFilterChange={onFilterChange}
            filter={filter}
            sx={{
              flexWrap: 'wrap',
              '.MuiChip-root': {
                marginBottom: 1.5,
                marginLeft: 1.5,
              }
            }} />
        </DropDownPopover>
      </>
    )
  }
)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export default FilterList
