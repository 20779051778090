import React, { ReactElement, useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Session from 'supertokens-web-js/recipe/session'
import Loading from '@components/Loading'

type AuthUserWrapperProps = {
  children: ReactElement;
}

export const AuthUserContext = ({ children }: AuthUserWrapperProps) => {

  const [ isAuth, setIsAuth ] = useState<boolean>(true)
  const [ isLoading, setLoading ] = useState<boolean>(true)
  const { pathname } = useLocation()
  const authUrl = `/auth?redirect=${pathname}`

  useEffect(() => {
    let cancel = false

    ;(async () => {

      if(!cancel) {
        if(await Session.doesSessionExist() && await Session.attemptRefreshingSession()) {
          setIsAuth(true)
        } else {
          setIsAuth(false)
        }
      }

      setLoading(false)

    })()

    return () => {
      cancel = true
    }

  }, [])

  if(isLoading) {
    return <Loading />
  }

  if(!isAuth) {
    return <Navigate to={authUrl} />
  }

  return children
}
