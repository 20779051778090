import { IconButton, styled } from '@mui/material'

const AvatarRemoveButton = styled(IconButton)(() => ({
  boxShadow: '0 1px 3px rgba(0,0,0,.3)',
  padding: 3,
  '&, &:hover': {
    background: '#FFF',
  }
}))

export default AvatarRemoveButton
