import React, { useRef, useState } from 'react'
import {
  SelectChangeEvent,
  ButtonProps,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Box
} from '@mui/material'
import { DropDownCloseIcon } from '@components/icons'
import { ContentFilterType, ContentFilterTypes } from '@src/types/usercontent'
import { userContentFilters } from '@components/UserContentCard/FilterItems'
import MultipleSelectItem from '@components/MultipleSelectItem'
import UserAutoCompleteControl from '@components/UserAutoCompleteControl'
import DropDownPopover from '@components/DropDownPopover'
import { UserModel } from '@src/types/models'
import AddFilterButton from '@components/AddFilterButton'

const filters: Array<{
  value: ContentFilterTypes,
  title: string
}> = [
  { value: ContentFilterTypes.STATUS, title: 'По статусу' },
  { value: ContentFilterTypes.MARKER, title: 'По маркеру' },
  { value: ContentFilterTypes.USER,   title: 'По пользователю' },
]

type AddFilterButtonType = ButtonProps & {
  onFilterChange: (filters: ContentFilterType) => void;
  filter: ContentFilterType;
  disableFilters?: ContentFilterTypes[];
}

const AddUserContentFilterButton = styled(
  ({ filter, onFilterChange, disableFilters = [], ...props }: AddFilterButtonType) => {
    const [ filterControlsPopoverAnchorEl, setFilterControlsPopoverAnchorEl ] = useState<HTMLButtonElement | null>(null)
    const filterTypeSelectRef = useRef<HTMLElement>()
    const [ searchType, setSearchType ] = useState<ContentFilterTypes | ''>('')

    const filterPopoverClose = () => {
      setFilterControlsPopoverAnchorEl(null)
      setTimeout(() => setSearchType(''), 300)
    }

    return (
      <>
        <AddFilterButton {...props} onClick={
          (event: React.MouseEvent<HTMLButtonElement>) => {
            setFilterControlsPopoverAnchorEl(event.currentTarget)
          }
        } />
        <DropDownPopover
          alignX="center"
          open={!!filterControlsPopoverAnchorEl}
          anchorEl={filterControlsPopoverAnchorEl}
          id={filterControlsPopoverAnchorEl ? 'filter-popover' : undefined}
          onClose={() => setFilterControlsPopoverAnchorEl(null)}
          ref={(instance) => {
            if (instance) {
              filterTypeSelectRef.current = instance.querySelector<HTMLElement>('.MuiPaper-root')!
            }
          }}
          sx={(theme) => ({
            '.MuiPaper-root': {
              padding: theme.spacing(2, 4, 2, 2),
            }
          })}
        >
          <IconButton onClick={filterPopoverClose} sx={{ position: 'absolute', top: 0.5, right: 0.5 }}>
            <DropDownCloseIcon />
          </IconButton>
          <Box>
            <FormControl size="small" sx={{ width: 200 }}>
              <InputLabel>Параметр</InputLabel>
              <Select
                onChange={(e) => setSearchType(e.target.value as ContentFilterTypes)}
                value={searchType}
                label="Параметр"
                fullWidth
              >
                {filters
                  .filter(f => !disableFilters.includes(f.value))
                  .map(filter => (
                    <MenuItem key={filter.value} value={filter.value}>
                      {filter.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {(() => {
              switch (searchType) {
                case ContentFilterTypes.STATUS:
                  return (
                    <FormControl size="small" sx={{ width: 200, ml: 1 }}>
                      <InputLabel>Значение</InputLabel>
                      <Select
                        multiple
                        fullWidth
                        label="Значение"
                        value={(filter?.status__in || []).map(String)}
                        renderValue={(selected) => (
                          selected.map(id => userContentFilters.statuses[ id ]).join(', ')
                        )}
                        onChange={(e: SelectChangeEvent<string[]>) => {
                          const statuses = Array.isArray(e.target.value) ? e.target.value : [ e.target.value ]
                          const status__in = statuses.length ? statuses.map(Number) : undefined
                          onFilterChange({ ...filter, status__in })
                        }}
                      >
                        {Object.entries(userContentFilters.statuses).map(([ id, label ]) => (
                          <MultipleSelectItem
                            checked={(filter?.status__in || []).includes(Number(id))}
                            value={id}
                            key={id}
                          >
                            {label}
                          </MultipleSelectItem>
                        ))}
                      </Select>
                    </FormControl>
                  )
                case ContentFilterTypes.MARKER:
                  return (
                    <FormControl size="small" sx={{ width: 200, ml: 1 }}>
                      <InputLabel>Значение</InputLabel>
                      <Select
                        multiple
                        fullWidth
                        label="Значение"
                        value={(filter?.markers__in || []).map(String)}
                        renderValue={(selected) => (
                          selected.map(id => userContentFilters.markers[ id ]).join(', ')
                        )}
                        onChange={(e: SelectChangeEvent<string[]>) => {
                          const markers = Array.isArray(e.target.value) ? e.target.value : [ e.target.value ]
                          const markers__in = markers.length ? markers.map(Number) : undefined
                          onFilterChange({ ...filter, markers__in })
                        }}
                      >
                        {Object.entries(userContentFilters.markers).map(([ id, label ]) => (
                          <MultipleSelectItem
                            checked={(filter?.markers__in || []).includes(Number(id))}
                            value={id}
                            key={id}
                          >
                            {label}
                          </MultipleSelectItem>
                        ))}
                      </Select>
                    </FormControl>
                  )
                case ContentFilterTypes.USER:
                  return (
                    <UserAutoCompleteControl
                      variant="outlined"
                      fullWidth
                      size="small"
                      sx={{ width: 500, ml: 1 }}
                      popoverAnchorEl={filterTypeSelectRef.current!}
                      onSelect={(user: UserModel) => {
                        onFilterChange({
                          ...filter,
                          user__in: [{
                            id: user.id,
                            name: user.profile?.nickname || user.profile?.firstname || user.id.split('-')[0]
                          }]
                        })
                        setFilterControlsPopoverAnchorEl(null)
                        setSearchType('')
                      }}
                    />
                  )
                default:
                  return <></>
              }
            })()}
          </Box>
        </DropDownPopover>
      </>
    )
  }
)(({ theme: { spacing }}) => ({
  fontSize: 14,
  '.MuiSvgIcon-root': {
    marginRight: spacing(0.4),
    height: 18,
    width: 18,
  }
}))

export default AddUserContentFilterButton
