import React from 'react'
import AvatarRemoveButton from '@components/UserCard/AvatarRemoveButton'
import { CloseIcon, UndoIcon } from '@components/icons'
import UserAvatar from '@components/UserAvatar'
import { Badge } from '@mui/material'
import { UserProfile } from '@src/types/models'
import { UserCardFormData } from '@components/UserCard/types'

type AvatarBadgeProps = {
  editable: boolean;
  formData: UserCardFormData;
  profile: UserProfile;
  onRemoveAvatar?: () => void;
  onRestoreAvatar?: () => void;
}

const AvatarBadge = ({ editable, formData, profile, onRemoveAvatar, onRestoreAvatar }: AvatarBadgeProps) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      sx={{
        '.MuiBadge-badge': {
          bottom: 7,
          right: 22
        }
      }}
      badgeContent={(() => {

        if(!editable || (!formData.picture && !profile.picture)) {
          return
        }

        if(!formData.picture && profile.picture) {
          return (
            <AvatarRemoveButton sx={{ p: '3px' }} onClick={onRestoreAvatar}>
              <UndoIcon color="primary" size={10} />
            </AvatarRemoveButton>
          )
        }

        return (
          <AvatarRemoveButton sx={{ p: '4px' }} onClick={onRemoveAvatar}>
            <CloseIcon color="primary" size={7} />
          </AvatarRemoveButton>
        )

      })()}>
      <UserAvatar profile={formData} size={48} sx={{ mr: 2 }} />
    </Badge>
  )
}

export default AvatarBadge
