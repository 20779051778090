import React from 'react'
import { StyledComponent } from '@mui/styles'
import { CSSProperties } from '@mui/styles/withStyles/withStyles'
import { IconProps as MuiIconProps, styled } from '@mui/material'

import { ReactComponent as Complaint } from '@icons/complaint.svg'
import { ReactComponent as Content } from '@icons/content.svg'
import { ReactComponent as Users } from '@icons/users.svg'
import { ReactComponent as Services } from '@icons/services.svg'
import { ReactComponent as Admin } from '@icons/admin.svg'
import { ReactComponent as Dashboard } from '@icons/dashboard.svg'
import { ReactComponent as Logo } from '@icons/logo.svg'
import { ReactComponent as Logout } from '@icons/logout.svg'
import { ReactComponent as Profile } from '@icons/profile.svg'
import { ReactComponent as DropDownClose } from '@icons/dropdown-close.svg'
import { ReactComponent as Close } from '@icons/close.svg'
import { ReactComponent as Anonymous } from '@icons/anonymous.svg'
import { ReactComponent as More } from '@icons/more.svg'
import { ReactComponent as Redo } from '@icons/redo.svg'
import { ReactComponent as Play } from '@icons/play.svg'
import { ReactComponent as Menu } from '@icons/menu.svg'
import { ReactComponent as LiveStream } from '@icons/live-stream.svg'
import { ReactComponent as Follow } from '@icons/follow.svg'

type SvgProps = React.SVGProps<SVGSVGElement>
type SvgIconComponent = React.FC<SvgProps>

type IconProps = {
  color?: MuiIconProps['color'] | string;
  sx?: MuiIconProps['sx'];
  size?: number | string;
}

const wrapper = (SvgIcon: SvgIconComponent): StyledComponent<IconProps> => {
  return styled((props: SvgProps) => (
    <SvgIcon { ...props } aria-hidden='true' />
  ))<IconProps>(
    ({ theme, color, size }) => {

      const styles: CSSProperties = {}

      switch (color) {
        case 'primary':
        case 'secondary':
        case 'error':
        case 'warning':
        case 'info':
        case 'success':
          styles.fill = theme.palette[color].main
          break
        case 'action':
          styles.fill = theme.palette.action.active
          break
        case 'disabled':
          styles.fill = theme.palette.action.disabled
          break
        case 'inherit':
          styles.fill = 'currentColor'
          break
        default:
          styles.fill = color
      }

      if(styles.fill) {
        styles.path = {
          fill: styles.fill
        }
      }

      if(size) {
        styles.width = size
        styles.height = size
      }

      return styles
    }
  )
} 

export const ComplaintIcon = wrapper(Complaint)
export const ContentIcon = wrapper(Content)
export const UsersIcon = wrapper(Users)
export const ServicesIcon = wrapper(Services)
export const AdminIcon = wrapper(Admin)
export const DashboardIcon = wrapper(Dashboard)
export const LogoIcon = wrapper(Logo)
export const LogoutIcon = wrapper(Logout)
export const ProfileIcon = wrapper(Profile)
export const DropDownCloseIcon = wrapper(DropDownClose)
export const CloseIcon = wrapper(Close)
export const AnonymousIcon = wrapper(Anonymous)
export const MoreIcon = wrapper(More)
export const PlayIcon = wrapper(Play)
export const MenuIcon = wrapper(Menu)
export const LiveStreamIcon = wrapper(LiveStream)
export const FollowIcon = wrapper(Follow)

export const RedoIcon = wrapper(Redo)

export const UndoIcon = styled(RedoIcon)({
  transform: 'scaleX(-1)',
})

export {
  ThumbUp as LikeIcon,
  ThumbDown as DislikeIcon,
  QuestionAnswer as CommentsIcon,
  ThumbUpOffAlt as LikeOutlineIcon,
  ThumbDownOffAlt as DislikeOutlineIcon
} from '@mui/icons-material'
