import { UserProfile } from '@src/types/models'
import { createContext, useContext } from 'react'

export type UserCardFormData = UserProfile;

export type ContentItemContextProps = {
  setEditable: (value: boolean) => void;
  editable: boolean;
}

export const ContentItemContext = createContext<ContentItemContextProps>({
  setEditable: () => {},
  editable: false,
})

export const useContentItemContext = () => useContext<ContentItemContextProps>(ContentItemContext)
