import moment from 'moment'

export const dateFormat = (value: string) => moment(value).format('DD.MM.YYYY')
export const dateTimeFormat = (value: string) => moment(value).format('DD.MM.YYYY HH:mm')
export const timeFormat = (value: string) => moment(value).format('HH:mm')

export const formatVideoTime = (seconds: number) => {
  if(seconds >= 0) {
    return moment
      .utc(moment.duration(seconds, 'seconds').asMilliseconds())
      .format((seconds / 60 >= 60 ? 'HH:' : '') + 'mm:ss')
  } else {
    return '00:00'
  }
}
