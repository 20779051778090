import React from 'react'
import { Box, BoxProps, Typography } from '@mui/material'

export type NotFoundPageProps = BoxProps & {
  children?: React.ReactNode
}

const PageNotFound = ({ children, ...props }: NotFoundPageProps) => {
  return (
    <Box { ...props }>
      <Typography variant={'h5'} color={'error.light'} align={'center'}>
        {children || 'Страница не найдена!'}
      </Typography>
    </Box>
  )
}

export default PageNotFound
