import { createApi, QueryActionCreatorResult, QueryDefinition } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryConfig } from '@utils/api'
import { ListResponseWithPagination, PartialRequestQueryArgs } from '@src/types'
import { UserCounters, UserModel } from '@src/types/models'
import { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'

type UserRequestQueryArgs = PartialRequestQueryArgs & {
  order?: string;
  filter?: {
    nickname?: string;
    id__in?: string[];
  }
}

export type UsersQueryDefinition = QueryActionCreatorResult<
  QueryDefinition<UserRequestQueryArgs, BaseQueryFn, never, ListResponseWithPagination<UserModel>, 'userApi'>
>

export type UserInfoQueryDefinition = QueryActionCreatorResult<
  QueryDefinition<string, BaseQueryFn, never, UserModel | null, 'userApi'>
>

export type UserCountersQueryDefinition = QueryActionCreatorResult<
  QueryDefinition<string, BaseQueryFn, never, UserCounters | null, 'userApi'>
>

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryConfig(),
  endpoints: build => ({
    userList: build.query<ListResponseWithPagination<UserModel>, UserRequestQueryArgs>({
      query: ({ page, page_size = 20, order, filter }) => ({
        url: 'user/',
        params: { page, page_size, order, filter: filter && JSON.stringify(filter) }
      }),
    }),
    userInfo: build.query<UserModel | null, string>({
      query: (userId: string) => ({
        url: 'user/',
        params: { page: 1, page_size: 1, filter: JSON.stringify({ id__in: [ userId ] }) }
      }),
      transformResponse: (resp: ListResponseWithPagination<UserModel>) => {
        return resp.results[0] || null
      }
    }),
    userCounters: build.query<UserCounters, string>({
      query: (userId: string) => ({
        url: 'counters/user/',
        params: { ids: JSON.stringify([ userId ]) }
      }),
      transformResponse: (resp: {[userId: string]: UserCounters}, meta: FetchBaseQueryMeta, userId: string) => {
        return resp[ userId ] || null
      }
    }),
  })
})

export const {
  useUserListQuery,
  useUserInfoQuery,
  useLazyUserInfoQuery,
  useUserCountersQuery,
  useLazyUserCountersQuery
} = userApi
