import React from 'react'
import { Box, BoxProps, styled, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import { black } from '@src/theme/colors'

const EmptyResult = styled((props: BoxProps) => {
  return (
    <Box {...props}>
      <Box>
        <Search />
        <Typography>Ничего не найдено</Typography>
      </Box>
    </Box>
  )
})(({ theme:  { spacing }}) => ({
  display: 'flex',
  width: '100%',
  height: 'calc(100vh - 200px)',
  alignItems: 'center',
  justifyContent: 'center',
  '> .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  '.MuiSvgIcon-root': {
    color: black[100],
    width: 65,
    height: 65,
  },
  '.MuiTypography-root': {
    color: black[50],
    fontSize: '20px',
    fontWeight: 700,
    marginTop: spacing(1)
  }
}))

export default EmptyResult
