import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryConfig } from '@utils/api'
import { UserInfo } from '@src/types/models'
import { SUPER_TOKEN_API_HOST } from '@src/config'

export const adminUserApi = createApi({
  reducerPath: 'adminUserApi',
  baseQuery: baseQueryConfig({ baseUrl: SUPER_TOKEN_API_HOST }),
  endpoints: build => ({
    getUserInfo:  build.query<UserInfo, void>({
      query: () => ({
        url: 'my_user_info/'
      }),
    })
  })
})

export const {
  useGetUserInfoQuery
} = adminUserApi
