import React, { Dispatch, ForwardedRef, forwardRef, SetStateAction, useEffect, useState } from 'react'
import {
  Box,
  BoxProps,
  Chip,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Typography
} from '@mui/material'
import { UserContentsModel } from '@src/types/models'
import { ContentStatusTitles, ContentTypeTitlesPlural, UserContentStatuses, UserContentTypes } from '@src/types'
import { dateFormat } from '@utils/datetime'
import { black } from '@src/theme/colors'
import { MenuIcon } from '@components/icons'
import DropDown from '@components/DropDown'
import { DeleteOutline, Edit, ExpandMore, Info, Public, Undo } from '@mui/icons-material'
import { ContentItemContext, ContentItemContextProps } from '@components/UserCard/types'
import ContentItemCounters from '@components/UserCard/ContentItem/ContentItemCounters'
import ContentItemComments from '@components/UserCard/ContentItem/ContentItemComments'
import ConfirmDialog from '@components/ConfirmDialog'
import { useChangeContentStatusMutation } from '@api/usercontents'
import { indigo } from '@mui/material/colors'
import InlineButton from '@components/InlineButton'
import Loading from '@components/Loading'
import PostContentItem from '@components/UserContentCard/PostContentItem'
import VideoContentItem from '@components/UserContentCard/VideoContentItem'
import EditIcon from '@mui/icons-material/Edit'
import ChipButton from '@components/ChipButton'

export type ContentItemProps = Omit<BoxProps, 'children'> & {
  userContent: UserContentsModel;
  inlineMenu?: boolean;
  onEditable?: ((editable: boolean) => void) | Dispatch<SetStateAction<boolean>>;
}

const ContentItem = styled(
  forwardRef((
    { userContent, inlineMenu, onEditable, ...props }: ContentItemProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const isDeleted = userContent.status === UserContentStatuses.DELETE
    const label = ContentTypeTitlesPlural[ userContent.type ]
    const shortId = userContent.id.split('-')[ 0 ]

    const [ editable, setEditable ] = useState<boolean>(false)

    const [ restoreDialogIsOpen, setRestoreDialogIsOpen ] = useState<boolean>(false)
    const restoreDialogClose = () => setRestoreDialogIsOpen(false)
    const restoreDialogOpen = () => setRestoreDialogIsOpen(true)

    const [ deleteDialogIsOpen, setDeleteDialogIsOpen ] = useState<boolean>(false)
    const deleteDialogClose = () => setDeleteDialogIsOpen(false)
    const deleteDialogOpen = () => setDeleteDialogIsOpen(true)

    const [ changeContentStatus, changeStatusResult ] = useChangeContentStatusMutation()

    const deleteHandle = () => {
      changeContentStatus({ contentId: userContent.content.id, status: UserContentStatuses.DELETE })
      deleteDialogClose()
    }

    const restoreHandle = () => {
      changeContentStatus({ contentId: userContent.content.id, status: UserContentStatuses.PUBLISHED })
      restoreDialogClose()
    }

    const changeStatus = (status: UserContentStatuses) => {
      changeContentStatus({ contentId: userContent.content.id, status })
    }

    const openHandle = () => {
      window.open(`/content/${userContent.id}`)
    }

    const editHandle = () => {
      setEditable(true)
    }

    const contextProps: ContentItemContextProps = {
      editable,
      setEditable
    }

    useEffect(() => {
      onEditable && onEditable(editable)
    }, [ editable ])

    return (
      <>
        <ContentItemContext.Provider value={contextProps}>
          <Box {...props} ref={ref}>
            {changeStatusResult.isLoading && (
              <Loading
                sx={{ borderRadius: 1.25 }}
                position="absolute"
                backdrop="white"
              />
            )}
            <Box sx={!isDeleted ? {} : {
              backgroundColor: indigo[ 50 ],
              borderRadius: 1.5,
              p: 1.5,
            }}>
              {isDeleted && (
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  color: 'primary.main',
                  mb: 2
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Info sx={{ mr: 1 }} /> Контент удален
                  </Box>
                  <InlineButton
                    sx={{ textTransform: 'uppercase' }}
                    onClick={restoreDialogOpen}
                    disableRipple={false}
                  >
                    Восстановить
                  </InlineButton>
                </Box>
              )}
              <Box sx={{ opacity: isDeleted ? 0.7 : 1 }}>

                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: inlineMenu ? 1.5 : 1
                }}>

                  {inlineMenu ? (
                    <>
                      {!editable ? (
                        <Chip size='small' label={ContentStatusTitles[userContent.status]} />
                      ) : (
                        <>
                          <DropDown className='ContentStatusDropDown' button={<ChipButton />} label={
                            <>
                              {ContentStatusTitles[userContent.status]}
                              <ExpandMore sx={{ fontSize: 14, right: -1.5, position: 'relative' }} />
                            </>
                          }>
                            {Object.entries(ContentStatusTitles).map(([ status, label ]) => (
                              <ListItem
                                onClick={() => changeStatus(Number(status) as UserContentStatuses)}
                                className={Number(status) == userContent.status ? 'active' : ''}
                                disablePadding
                                key={status}
                              >
                                <ListItemButton>
                                  <ListItemText primary={label} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </DropDown>
                        </>
                      )}

                      {!editable ? (
                        <InlineButton
                          sx={{ fontSize: 14, fontWeight: 400, textTransform: 'none' }}
                          onClick={() => setEditable(true)}
                        >
                          <EditIcon sx={{ mr: 0.5 }} />
                          Редактировать
                        </InlineButton>
                      ) : (
                        <>
                          {/* @todo Add form buttons */}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography sx={{ span: { textTransform: 'lowercase' }}}>
                        ID: {shortId} (<span>{label}</span>)
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography color={black[ 100 ]}>
                          {dateFormat(userContent.created)}
                        </Typography>
                        <DropDown className='MenuDropDown' label={<MenuIcon />} alignX="right">
                          <ListItem disablePadding onClick={openHandle}>
                            <ListItemButton>
                              <ListItemIcon>
                                <Public />
                              </ListItemIcon>
                              <ListItemText primary="Перейти" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding onClick={editHandle}>
                            <ListItemButton>
                              <ListItemIcon>
                                <Edit />
                              </ListItemIcon>
                              <ListItemText primary="Редактировать" />
                            </ListItemButton>
                          </ListItem>
                          {isDeleted ? (
                            <ListItem disablePadding onClick={restoreDialogOpen}>
                              <ListItemButton>
                                <ListItemIcon>
                                  <Undo className="deleteIcon" />
                                </ListItemIcon>
                                <ListItemText primary="Восстановить" />
                              </ListItemButton>
                            </ListItem>
                          ) : (
                            <ListItem disablePadding onClick={deleteDialogOpen}>
                              <ListItemButton>
                                <ListItemIcon>
                                  <DeleteOutline className="deleteIcon" />
                                </ListItemIcon>
                                <ListItemText primary="Удалить" />
                              </ListItemButton>
                            </ListItem>
                          )}
                        </DropDown>
                      </Box>
                    </>
                  )}

                </Box>

                {(() => {
                  switch (userContent.type) {
                    case UserContentTypes.POST:
                      return <PostContentItem userContent={userContent} />
                    case UserContentTypes.VIDEO:
                    case UserContentTypes.STREAM:
                      return (
                        <Box>
                          <VideoContentItem userContent={userContent} />
                          <Typography>{userContent.content.text}</Typography>
                        </Box>
                      )
                    case UserContentTypes.COMMENT:
                      return <Box>Комментарий</Box>
                    case UserContentTypes.STORIES:
                      return <Box>Сторис</Box>
                  }
                })()}

                <ContentItemCounters sx={{ mt: 2.5 }} userContentId={userContent.id} />
                <ContentItemComments sx={{ mt: 1.5 }} userContentId={userContent.id} />
              </Box>
            </Box>
          </Box>
        </ContentItemContext.Provider>

        <ConfirmDialog
          open={deleteDialogIsOpen}
          onCancel={deleteDialogClose}
          onConfirm={deleteHandle}
          okText="Удалить"
          color="error"
        >
          Удалить контент?
        </ConfirmDialog>

        <ConfirmDialog
          open={restoreDialogIsOpen}
          onCancel={restoreDialogClose}
          onConfirm={restoreHandle}
          okText="Восстановить"
          color="primary"
        >
          Восстановить контент?
        </ConfirmDialog>
      </>
    )
  })
)(({ theme }) => ({
  position: 'relative',
  '.MenuDropDown': {
    marginLeft: theme.spacing(1),
    '.deleteIcon': {
      position: 'relative',
      fontSize: 30,
      left: -4
    }
  },
  '.ContentStatusDropDown': {
    '.MuiListItemButton-root': {
      paddingLeft: theme.spacing(3),
    },
  }
}))

export default ContentItem
