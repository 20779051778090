import { UserContentFilterType } from '@api/usercontents'

export type FilterUserType = {
  id: string;
  name: string;
}

export type ContentFilterType = Omit<UserContentFilterType, 'user__in'> & {
  user__in?: FilterUserType[]
}

export type ContentFilterItemListType = Array<{
  type: keyof ContentFilterType;
  value: number | string | FilterUserType;
}>

export enum ContentFilterTypes {
  STATUS = 'status',
  TYPE = 'type',
  MARKER = 'marker',
  USER = 'user',
}
