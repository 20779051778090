import React, { useState } from 'react'
import CardDrawerHeader from '@components/CardDrawerHeader'
import CardDrawerToolbar from '@components/CardDrawerToolbar'
import { Tab, Tabs } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import ContentIcon from '@mui/icons-material/VideoLibrary'
import CardDrawerBody from '@components/CardDrawerBody'
import UserInfoTab from '@components/UserCard/UserInfoTab'
import ContentListTab from '@components/UserContentCard/ContentListTab'
import { UserModel } from '@src/types/models'


type UserCardProps = {
  user: UserModel;
  onClose?: () => void;
}

enum CardTabs {
  USER = 0,
  CONTENT = 1
}

const UserCard = ({ user, onClose }: UserCardProps) => {
  const [ activeTab, setActiveTab ] = useState<CardTabs>(CardTabs.USER)

  const openMoreCard = () => {
    window.open(`/users/${user.id}`)
  }

  return (
    <>
      <CardDrawerHeader>
        <CardDrawerToolbar onClose={onClose} onMore={openMoreCard}>
          Пользователь / ID: {user.id.split('-')[0]}
        </CardDrawerToolbar>
        <Tabs variant='fullWidth' value={activeTab} onChange={(e, v) => setActiveTab(v)}>
          <Tab label='Данные' icon={<PersonIcon />} iconPosition='start' />
          <Tab label='Контент' icon={<ContentIcon />} iconPosition='start' />
        </Tabs>
      </CardDrawerHeader>
      <CardDrawerBody>
        {(() => {
          switch (activeTab) {
            case CardTabs.USER:
              return <UserInfoTab user={user} />

            case CardTabs.CONTENT:
              return <ContentListTab user={user} />
          }
        })()}
      </CardDrawerBody>
    </>
  )
}

export default UserCard
