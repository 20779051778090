import React from 'react'
import { Checkbox, MenuItem, MenuItemProps, styled } from '@mui/material'

type MultipleSelectItemProps = MenuItemProps & {
  checked?: boolean;
}

const MultipleSelectItem = styled(({ checked, children, ...props }: MultipleSelectItemProps) => (
  <MenuItem { ...props }>
    {children}
    <Checkbox checked={Boolean(checked)} />
  </MenuItem>
))({
  display: 'flex',
  justifyContent: 'space-between'
})

export default MultipleSelectItem
