import React, { useEffect, useMemo, useState } from 'react'
import { Box, BoxProps, CircularProgress, Collapse, styled } from '@mui/material'
import { useCommentsQuery } from '@api/comments'
import { CommentModel, UserModel } from '@src/types/models'
import { useUserListQuery } from '@api/user'
import { ExpandMore } from '@mui/icons-material'
import InlineButton from '@components/InlineButton'
import CommentItem from '@components/UserCard/ContentItem/CommentItem'
import { ContentItemCommentsContext } from '@components/UserCard/ContentItem/ContentItemCommentsContext'

export type ContentItemCommentsType = BoxProps & {
  userContentId: string;
}

const ContentItemComments = styled(({ userContentId, ...props }: ContentItemCommentsType) => {

  const [ comments, setComments ] = useState<CommentModel[]>([])
  const [ users, setUsers ] = useState<{ [ userId: string ]: UserModel }>({})
  const [ total, setTotal ] = useState<number>(0)
  const [ page, setPage ] = useState<number>(1)
  const [ open, setOpen ] = useState<boolean>(false)

  const { isFetching: commentsFetching, data } = useCommentsQuery({
    page,
    page_size: 10,
    filter: {
      usercontent: userContentId
    }
  })

  const userIds = Array.from(new Set(comments.map(c => c.user_id)))

  const commentUsers = useUserListQuery({
    page_size: userIds.length,
    page: 1,
    filter: {
      id__in: userIds
    }
  }, {
    skip: commentsFetching || !comments.length
  })

  const isFetching = useMemo(() => (
    commentsFetching || commentUsers.isFetching
  ), [ commentsFetching, commentUsers.isFetching ])

  useEffect(() => {
    return () => setComments([])
  }, [])

  useEffect(() => {
    if (!commentsFetching && data) {
      setComments(prevState => {
        const result = [ ...prevState ]
        data.results.forEach(it => {
          const index = result.findIndex(it2 => it2.id === it.id)
          if(index !== -1) {
            result[index] = { ...result[index], ...it }
          } else {
            result.push(it)
          }
        })
        return result
      })
      setTotal(data.pagination.total_count)
    }
  }, [ commentsFetching, data ])

  useEffect(() => {
    if (!commentUsers.isFetching && commentUsers.data) {
      setUsers(Object.fromEntries(commentUsers.data.results.map(u => [ u.id, u ])))
    }
  }, [ commentUsers.isFetching, commentUsers.data ])

  const commentsTree = useMemo(
    function commentsTreeCreate(parentId: string | null = null) {

      const result: Array<CommentModel & {
        childItems?: CommentModel[]
      }> = []

      comments.forEach(comment => {
        if (comment.content.parent === parentId) {
          result.push({ ...comment, childItems: commentsTreeCreate(comment.content.id) })
        }
      })

      return result

    }, [ comments ])

  return (
    <Box {...props}>
      <InlineButton sx={{ fontSize: 14 }} onClick={() => setOpen(open => !open)}>
        Комментарии
        <Box sx={{ display: 'inline-flex', alignItems: 'center', ml: 0.5, height: 20 }}>
          {isFetching && !total ? <CircularProgress size={14} /> : (
            <>
              ({ total })
              {total > 0 && (
                <ExpandMore sx={{ ml: 0.5, transform: `rotate(${open ? 180 : 0}deg)` }} />
              )}
            </>
          )}
        </Box>
      </InlineButton>

      <ContentItemCommentsContext.Provider value={{ users, isUserFetching: commentUsers.isFetching }}>
        <Collapse in={open}>
          {commentsTree.map(comment => (
            <CommentItem key={comment.id} comment={comment} sx={{ mt: 1 }} />
          ))}
          {comments.length < total && (
            <Box sx={{ textAlign: 'center', mt: 1 }}>
              {commentsFetching ? <CircularProgress size={20} /> : (
                <InlineButton sx={{ fontSize: 14 }} onClick={() => setPage(p => p + 1)}>
                  Показать ещё...
                </InlineButton>
              )}
            </Box>
          )}
        </Collapse>
      </ContentItemCommentsContext.Provider>
    </Box>
  )
})(() => ({}))

export default ContentItemComments
