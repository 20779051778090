import { Box, styled } from '@mui/material'

const FilterChipGroup = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    '.MuiChip-root + .MuiChip-root': {
      marginLeft: theme.spacing(1.5),
    }
  })
)

export default FilterChipGroup
