import React from 'react'
import CardDrawer from '@components/CardDrawer'
import UserCard from '@components/UserCard'
import { UserModel } from '@src/types/models'

type UserCardDrawerProps = {
  user: UserModel | null;
  onClose?: () => void;
}

const UserCardDrawer = ({ user, onClose }: UserCardDrawerProps) => {
  return (
    <CardDrawer open={!!user} onClose={onClose} id={'UserCardDrawer'}>
      {user && <UserCard user={user} onClose={onClose} />}
    </CardDrawer>
  )
}

export default UserCardDrawer
