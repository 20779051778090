import React, { ReactNode } from 'react'
import ListItem, { ListItemProps } from '@components/Layout/ListItem'
import { List, ListProps, styled } from '@mui/material'
import ListNestedItem, { ListNestedItemProps } from '@components/Layout/ListNestedItem'
import { AdminIcon, ComplaintIcon, ContentIcon, DashboardIcon, ServicesIcon, UsersIcon } from '@components/icons'

type SideBarItemProps = {
  label: string;
  to: string;
  icon?: ReactNode;
  items?: SideBarItemProps[];
  end?: boolean;
};

const items: SideBarItemProps[] = [
  { to: '/', label: 'Дашборд', icon: <DashboardIcon /> },
  {
    to: '/content',
    label: 'Контент',
    icon: <ContentIcon /> ,
    items: [
      { to: '/content', label: 'Весь', end: true },
      { to: '/content/posts', label: 'Посты', end: true },
      { to: '/content/videos', label: 'Видео', end: true },
      { to: '/content/streams', label: 'Стримы', end: true  },
      { to: '/content/comments', label: 'Комментарии', end: true  },
      { to: '/content/stories', label: 'Сторис', end: true  },
    ]
  },
  {
    label: 'Пользователи',
    to: '/users',
    icon: <UsersIcon />,
    items: [
      { label: 'Все', to: '/users', end: true },
      { label: 'Новые', to: '/users/new' },
    ]
  },
  { to: '/complaint', label: 'Жалобы', icon: <ComplaintIcon /> },
  { to: '/services', label: 'Сервисы', icon: <ServicesIcon /> },
  { to: '/settings', label: 'Админпанель', icon: <AdminIcon /> },
]

export type SideBarProps = ListProps & {}

const SideBar = styled(({ ...props }: SideBarProps) => (
  <List {...props}>
    {items.map((props) => (
      props.items
        ? <ListNestedItem {...(props as ListNestedItemProps)} key={props.label} />
        : <ListItem {...(props as ListItemProps)} key={props.label} />
    ))}
  </List>
))(({ theme: { spacing, palette, transitions }}) => ({
  '.MuiListItemButton-root': {
    justifyContent: 'initial',
    padding: spacing(0, 3.5),
    transition: transitions.create('background-color', {
      duration: 350
    }),
    minHeight: 48,
    ':hover': {
      backgroundColor: 'rgba(255,255,255,.2)',
    },
    '.MuiListItemIcon-root': {
      marginRight: spacing(1.6),
      justifyContent: 'center',
      minWidth: 0,
      padding: spacing(0.25),
      '.MuiSvgIcon-root, svg': {
        color: '#FAFAFA',
        width: 28,
        height: 28,
      },
    },
    '.MuiTypography-root': {
      color: '#FAFAFA',
    }
  },
  a: {
    textDecoration: 'none',
  },
  'a.active > .MuiListItemButton-root, .MuiListItemButton-root.active': {
    '.MuiTypography-root': {
      color: '#FFF',
      fontWeight: 700,
      letterSpacing: 0.4,
    },
    '.MuiListItemIcon-root': {
      backgroundColor: palette.primary.main,
      borderRadius: spacing(0.5)
    }
  }
}))

export default SideBar
