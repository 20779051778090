import {
  CommentStatuses,
  CommentTypes,
  ExplicitTypes,
  ModerationTypes,
  UserContentStatuses,
  UserContentTypes
} from '@src/types/index'

export type UserProfile = {
  id: string;
  city_id: string | null;
  city: string | null;
  country_id: string | null;
  country: string | null;
  currency: string | null;
  date_registered: string;
  firstname: string;
  lastname: string;
  nickname: null;
  gender: string | null;
  birthdate: string | null;
  picture: string | null;
  original_picture: string | null;
  phone: string;
  about_me: string | null;
  properties: string | null;
  following: string[];
  follower: string[]
};

export type UserModel = {
  id: string;
  is_active: boolean;
  is_purged: boolean;
  groups: [];
  date_joined: string;
  last_login: string;
  email: string | null;
  email_verified: boolean | null;
  profile: UserProfile | null;
}

export type UserInfo = {
  user_id: string;
  email: string;
  time_joined: number;
  first_name?: string;
  last_name?: string;
  tenant_ids: Array<'public'>,
}

export type UserContentCounters = {
  content_comments: number;
  content_like_both: number;
  content_like_dislike: number;
  content_like_like: number;
}

export type UserCounters = {
  comments_user: number;
  content_post_user: number;
  content_user: number;
  content_video_user: number;
  content_vstream_user: number;
  followers_user: number;
  like_both_user: number;
  like_dislike_user: number;
  like_like_user: number;
  subscriptions_user: number;
  user_content_like_both: number;
  user_content_like_dislike: number;
  user_content_like_like: number;
}

export enum ContentLocationStatuses {
  LIVE = 0,
  ARCHIVE = 1
}

export type UserContentsModel = {
  id: string;
  status: UserContentStatuses;
  type: UserContentTypes;
  created: string;
  updated: string;
  explicit: ExplicitTypes;
  moderation: ModerationTypes;
  retailmention: number;
  markers: number[],
  user_id: string;
  content: {
    id: string;
    uuid?: string;
    content: string;
    text: string;
    language: string;
    location_status: ContentLocationStatuses;
    images: Array<{
      image: string;
      width: number;
      height: number;
    }>;
  }
}

export enum Languages {
  RU = 'ru',
  EN = 'en'
}

export type CommentModel = {
  id: string;
  status: CommentStatuses;
  type: CommentTypes;
  created: string;
  updated: string;
  explicit: number | null;
  moderation: number | null;
  retailmention: number | null;
  markers: null | number[];
  user_id: string;
  content: {
    id: string;
    content: string;
    commented_content: string;
    parent: string | null;
    text: string;
    language: Languages;
  }
}
