import React, { Suspense, useMemo } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import SuperTokens from 'supertokens-web-js'
import Session from 'supertokens-web-js/recipe/session'
import EmailPassword from 'supertokens-web-js/recipe/emailpassword'
import { Provider } from 'react-redux'
import Layout from './components/Layout'
import AppRoutes from '@src/AppRoutes'
import store from './store'
import Loading from '@components/Loading'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { AuthUserContext } from '@components/AuthUserContext'
import { API_BASE_PATH, SUPER_TOKEN_API_HOST, API_NAME } from '@src/config'
import { useMediaQuery } from '@mui/material'
import { getDesignTokens } from '@src/theme'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const Auth = React.lazy(() => import('@pages/Auth'))
const Registration = React.lazy(() => import('@pages/Registration'))

SuperTokens.init({
  appInfo: {
    appName: API_NAME,
    apiDomain: SUPER_TOKEN_API_HOST,
    apiBasePath: API_BASE_PATH,
  },
  recipeList: [
    Session.init({
      tokenTransferMethod: 'header' // cookie | header
    }),
    EmailPassword.init()
  ]
})

function App() {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', {
    noSsr: true
  })

  const theme = useMemo(() => {
    // return createTheme(getDesignTokens(prefersDarkMode ? 'dark' : 'light'))
    return createTheme(getDesignTokens('light'))
  }, [ prefersDarkMode ])

  return (

    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/auth" element={<Auth />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/*" element={
              <AuthUserContext>
                <Layout>
                  <AppRoutes />
                </Layout>
              </AuthUserContext>
            } />
          </Routes>
        </Router>
      </ThemeProvider>
    </React.StrictMode>
  )
}

export default () => (
  <Provider store={store}>
    <Suspense fallback={<Loading />}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <App />
      </LocalizationProvider>
    </Suspense>
  </Provider>
)
