import { Drawer as MuiDrawer, styled } from '@mui/material'
import { drawerWidth } from '@components/Layout/mixins'

const Drawer = styled(MuiDrawer)(
  () => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '.MuiPaper-root': {
      background: 'linear-gradient(180deg, #000, #0E021E 100%)',
      color: '#FAFAFA'
    },
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
    }
  }),
)

export default Drawer
