import React, { useMemo } from 'react'
import { Box, BoxProps, styled, Typography } from '@mui/material'
import { CommentModel } from '@src/types/models'
import UserAvatar from '@components/UserAvatar'
import { getUserName } from '@src/utils'
import { dateFormat, timeFormat } from '@utils/datetime'
import InlineButton from '@components/InlineButton'
import { useContentItemCommentsContext } from '@components/UserCard/ContentItem/ContentItemCommentsContext'
import moment from 'moment'
import { useChangeCommentStatusMutation } from '@api/comments'
import { CommentStatuses } from '@src/types'
import { indigo } from '@mui/material/colors'
import { Info } from '@mui/icons-material'
import Loading from '@components/Loading'

export type CommentItemProps = BoxProps & {
  comment: CommentModel & {
    childItems?: CommentModel[]
  };
}

const CommentItem = styled(({ comment, className, ...props }: CommentItemProps) => {
  const { users, isUserFetching } = useContentItemCommentsContext()
  const user = users[comment.user_id]
  const date = useMemo(() => {
    switch (moment().diff(comment.created, 'day')) {
      case 0:
        return 'Сегодня'
      case 1:
        return 'Вчера'
      default:
        return dateFormat(comment.created)
    }
  }, [ comment.created ])
  const [ changeCommentStatus, changeCommentResult ] = useChangeCommentStatusMutation()
  const commentDelete = () => changeCommentStatus({ commentId: comment.id, status: CommentStatuses.DELETE })
  const commentRestore = () => changeCommentStatus({ commentId: comment.id, status: CommentStatuses.PUBLISHED })
  const isDeleted = comment.status === CommentStatuses.DELETE

  return (
    <Box { ...props } className={className + ' CommentItem'}>
      {changeCommentResult.isLoading && (
        <Loading
          position='absolute'
          backdrop='white'
          thickness={2}
          size={40}
        />
      )}
      <Box sx={!isDeleted ? {} : {
        backgroundColor: indigo[50],
        borderRadius: 1.5,
        p: 1.5,
      }}>
        {isDeleted && (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: 'primary.main',
            mb: 2
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Info sx={{ mr: 1 }} /> Комментарий удален
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex' }}>
          <UserAvatar profile={user?.profile} loading={isUserFetching} />
          <Box sx={{ flexGrow: 1, ml: 1 }}>

            <Box>
              <Typography fontWeight={700}>
                {isUserFetching ? '...' : getUserName(user?.profile)}
              </Typography>
              <Typography>{comment.content.text}</Typography>
            </Box>

            <Box sx={{
              alignItems: 'center',
              display: 'flex',
              mt: 0.5,
              '.MuiButton-root': {
                ml: 1.5,
                fontSize: 13,
                fontFamily: 'Inter'
              }
            }}>
              <Typography fontSize={13}>
                {date} в {timeFormat(comment.created)}
              </Typography>
              {isDeleted ? (
                <InlineButton
                  disabled={changeCommentResult.isLoading}
                  onClick={commentRestore}
                >
                  Восстановить
                </InlineButton>
              ) : (
                <InlineButton
                  sx={{ color: '#1D1D1D' }}
                  disabled={changeCommentResult.isLoading}
                  onClick={commentDelete}
                >
                  Удалить
                </InlineButton>
              )}
            </Box>

            {comment.childItems && comment.childItems.length > 0 && (
              <Box sx={{ mt: 1.5 }}>
                {comment.childItems.map(childComment => (
                  <CommentItem key={childComment.id} comment={childComment} sx={{ mb: 1 }} />
                ))}
              </Box>
            )}

          </Box>
        </Box>
      </Box>
    </Box>
  )
})(() => ({
  position: 'relative',
  width: '100%',
}))

export default CommentItem
