import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  BoxProps,
  Button,
  Divider,
  OutlinedInput,
  OutlinedInputProps,
  styled,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  Typography
} from '@mui/material'
import { UserModel, UserProfile } from '@src/types/models'
import { grey } from '@mui/material/colors'
import EditIcon from '@mui/icons-material/Edit'
import { dateFormat } from '@utils/datetime'
import { black } from '@src/theme/colors'
import Counters from '@components/UserCard/Counters'
import UserStatusChip from '@components/UserCard/UserStatusChip'
import { UserStatuses } from '@src/types'
import AvatarBadge from '@components/UserCard/AvatarBadge'
import { UserCardFormData } from '@components/UserCard/types'
import CardFormButtonGroup from '@components/CardFormButtonGroup'
import CardTable from '@components/CardTable'

type UserInfoTabProps = BoxProps & {
  user: UserModel;
}

type BaseFieldProps = Omit<OutlinedInputProps, 'name'> & {
  name: keyof UserProfile;
}

const BaseField = styled((props: BaseFieldProps) => {
  return <OutlinedInput {...props} autoComplete="off" size="small" fullWidth />
})({
  backgroundColor: grey[ 50 ],
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: black[ 'A200' ]
  },
})

const UserInfoTab = styled(({ user, ...props }: UserInfoTabProps) => {

  const [ profile, setProfile ] = useState<UserProfile>(Object.assign(user.profile || {}))
  const [ formData, setFormData ] = useState<UserCardFormData>(Object.assign(profile))
  const [ userStatus, setUserStatus ] = useState<UserStatuses>(UserStatuses.ACTIVE)
  const [ editable, setEditable ] = useState<boolean>(false)

  useEffect(() => {
    if (user.is_purged) {
      setUserStatus(UserStatuses.DELETE)
    } else if (!user.is_active) {
      setUserStatus(UserStatuses.BLOCK)
    } else {
      setUserStatus(UserStatuses.ACTIVE)
    }
  }, [ user ])

  const saveProfile = () => {
    setEditable(false)
    setProfile(formData)
  }

  const cancelChanges = () => {
    setEditable(false)
    setFormData(profile)
  }

  const statusChangeHandler = (status: UserStatuses) => {
    switch (status) {
      case UserStatuses.ACTIVE:
        break
      case UserStatuses.BLOCK:
        break
      case UserStatuses.DELETE:
        break
    }
  }

  const ProfileField = useCallback(({ name, ...props }: BaseFieldProps) => {
    return (
      <BaseField {...props} defaultValue={profile[ name ]} name={name} onChange={
        (e) => setFormData((prevState) => (
          { ...prevState, [ name ]: e.target.value }
        ))
      } />
    )
  }, [ profile ])

  const LabelCell = useCallback((props: TableCellProps) => {
    return <TableCell { ...props } sx={!editable ? {} : {
      '&&': { pt: '17px' }
    }} />
  }, [ editable ])

  return (
    <Box {...props}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 37, px: 3 }}>
        <Typography fontSize={15} fontWeight={700}>Общие данные</Typography>
        {!editable && (
          <Button
            sx={{ fontSize: 14, fontWeight: 400, textTransform: 'none' }}
            onClick={() => setEditable(true)}
          >
            <EditIcon sx={{ mr: 0.5 }} />
            Редактировать
          </Button>
        )}
      </Box>
      <Box sx={{ px: 3, pb: 2, flexGrow: 1, overflow: 'auto' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
          <AvatarBadge
            profile={profile}
            formData={formData}
            editable={editable}
            onRemoveAvatar={() => {
              setFormData((prevState) => (
                { ...prevState, picture: null }
              ))
            }}
            onRestoreAvatar={() => {
              setFormData((prevState) => (
                { ...prevState, picture: profile.picture }
              ))
            }}
          />
          {!editable ? (
            <Typography>{profile?.nickname}</Typography>
          ) : (
            <ProfileField name="nickname" />
          )}
        </Box>
        <CardTable>
          <TableBody>
            <TableRow>
              <TableCell sx={{ '&&': { verticalAlign: 'middle' }}}>
                Статус
              </TableCell>
              <TableCell>
                <UserStatusChip
                  onStatusChange={statusChangeHandler}
                  userStatus={userStatus}
                  editable={editable}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <LabelCell>Имя</LabelCell>
              <TableCell>
                {!editable ? profile?.firstname : (
                  <ProfileField name="firstname" />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <LabelCell>Фамилия</LabelCell>
              <TableCell>
                {!editable ? profile?.lastname : (
                  <ProfileField name="lastname" />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <LabelCell>Телефон</LabelCell>
              <TableCell>
                {!editable ? profile?.phone : (
                  <ProfileField name="phone" />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <LabelCell>Описание</LabelCell>
              <TableCell>
                {!editable ? profile?.about_me : (
                  <ProfileField name="about_me" multiline />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                {editable && (
                  <CardFormButtonGroup
                    onCancel={cancelChanges}
                    onSave={saveProfile}
                  />
                )}
                <Divider variant="fullWidth" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Зарегистрирован</TableCell>
              <TableCell>{dateFormat(user.date_joined)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Устройство</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <Divider variant="fullWidth" />
              </TableCell>
            </TableRow>
          </TableBody>
        </CardTable>
        <Counters userId={user.id} />
      </Box>
    </Box>
  )
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '.MuiTableCell-root': {
    verticalAlign: 'top',
  }
}))

export default UserInfoTab
