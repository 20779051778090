export interface PartialRequestQueryArgs {
  page: number;
  page_size?: number;
}

export interface SortOrderRequestQueryArgs {
  order?: string;
}

export interface FilterRequestQueryArgs {
  filter?: Record<string, unknown>;
}

export interface ListResponse<T> {
  results: T[];
  total: number;
}

export interface ListResponseWithPagination<T> extends ListResponse<T> {
  pagination: Pagination;
}

export interface Pagination {
  limit: number;
  offset: number;
  page: number;
  page_size: number;
  total_count: number;
}

export type BaseRequestFilters = {
  id__in?: string[];
  user__in?: string[];
  status__in?: number[];
  markers__in?: number[];
}

export type SortDirection = 'asc' | 'desc'

export enum UserStatuses {
  DELETE = 'delete',
  BLOCK = 'block',
  ACTIVE = 'active',
}

export enum UserContentStatuses {
  NEW = 0,
  PUBLISHED = 1,
  DELETE = 2
}

export enum UserContentTypes {
  ALL = 0,
  COMMENT = 1,
  POST = 4,
  VIDEO = 5,
  STREAM = 6,
  STORIES = 7,
}

export enum ModerationTypes {
  New = 0,
  AutoModerated = 1,
  ManualModerated = 2
}

export enum ExplicitTypes {
  NonExplicit = 0,
  Rude = 1,
  Explicit = 2
}

export const ContentStatusTitles = {
  [ UserContentStatuses.NEW ]: 'Новый',
  [ UserContentStatuses.PUBLISHED ]: 'Опубликован',
  [ UserContentStatuses.DELETE ]: 'Удалён',
}

export const ContentTypeTitles: {[typeId in UserContentTypes]: string} = {
  [ UserContentTypes.COMMENT ]: 'Комментарии',
  [ UserContentTypes.POST ]: 'Посты',
  [ UserContentTypes.VIDEO ]: 'Видео',
  [ UserContentTypes.STREAM ]: 'Стримы',
  [ UserContentTypes.STORIES ]: 'Сторис',
  [ UserContentTypes.ALL ]: 'Весь контент',
}

export const ContentTypeTitlesPlural: {[typeId in UserContentTypes]: string} = {
  [ UserContentTypes.COMMENT ]: 'Комментарий',
  [ UserContentTypes.POST ]: 'Пост',
  [ UserContentTypes.VIDEO ]: 'Видео',
  [ UserContentTypes.STREAM ]: 'Стрим',
  [ UserContentTypes.STORIES ]: 'Сторис',
  [ UserContentTypes.ALL ]: '--',
}

export const ModerationTypeTitles: {[typeId in ModerationTypes]: string} = {
  [ ModerationTypes.New ]: 'Новый',
  [ ModerationTypes.AutoModerated ]: 'Автоматическая модерация',
  [ ModerationTypes.ManualModerated ]: 'Ручная модерация',
}

export const ExplicitTypeTitles: {[typeId in ExplicitTypes]: string} = {
  [ ExplicitTypes.NonExplicit ]: 'Для всех',
  [ ExplicitTypes.Rude ]: '16+',
  [ ExplicitTypes.Explicit ]: '18+',
}


export enum CommentStatuses {
  NEW = 0,
  PUBLISHED = 1,
  DELETE = 2
}

export enum CommentTypes {
  COMMENT = 1
}
