import { styled, Table } from '@mui/material'
import { black } from '@src/theme/colors'

const CardTable = styled(Table)(
  ({ theme }) => ({
    '.MuiTableCell-root': {
      border: 'none',
      padding: theme.spacing(1, 0),
      '&:first-of-type': {
        width: 140,
        color: black[ 200 ]
      },
      '.MuiDivider-root': {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
      }
    }
  })
)

export default CardTable
