import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@mui/material'
import { UserModel } from '@src/types/models'
import { PER_PAGE_LIST } from '@src/config'
import CheckIcon from '@mui/icons-material/Check'
import { dateFormat } from '@utils/datetime'
import UserCardDrawer from '@components/UserCardDrawer'

type UsersTableProps = {
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPageChange: (event: unknown, newPage: number) => void;
  onSort: (property: string) => void;
  rowsPerPage: number;
  count: number;
  page: number;
  orderBy?: string;
  order?: 'asc' | 'desc';
  users: UserModel[];
  onSelect?: (user: UserModel) => void;
}

const UsersTable = ({
  onRowsPerPageChange,
  onPageChange,
  rowsPerPage,
  count,
  page,
  orderBy,
  order,
  users,
  onSort,
  onSelect
}: UsersTableProps) => {
  const tableRef = useRef<HTMLDivElement | null>(null)
  const [ inPopover, setInPopover ] = useState<boolean>(false)
  const [ activeUser, setActiveUser ] = useState<UserModel | null>(null)

  const openUserCardDrawer = (user: UserModel) => {
    setActiveUser(user)
  }

  const closeUserCardDrawer = () => {
    setActiveUser(null)
  }

  useEffect(() => {
    if(tableRef.current && !!tableRef.current.closest('.MuiPopover-root')) {
      setInPopover(true)
    }
  }, [ tableRef.current ])

  return (
    <>
      <TableContainer ref={tableRef}>
        <Table sx={{ minWidth: 500 }} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Ник</TableCell>
              <TableCell>Имя</TableCell>
              <TableCell>Фамилия</TableCell>
              <TableCell sortDirection={orderBy === 'date_joined' ? order : false}>
                <TableSortLabel
                  onClick={() => onSort && onSort('date_joined')}
                  direction={orderBy === 'date_joined' ? order : 'asc'}
                  active={orderBy === 'date_joined'}
                >
                  Дата регистрации
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'last_login' ? order : false}>
                <TableSortLabel
                  onClick={() => onSort && onSort('last_login')}
                  direction={orderBy === 'last_login' ? order : 'asc'}
                  active={orderBy === 'last_login'}
                >
                  Последняя активность
                </TableSortLabel>
              </TableCell>
              {onSelect && (
                <TableCell></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user: UserModel) => {
              const shortId = user.id.split('-')[0]

              return (
                <TableRow key={user.id}>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {inPopover ? shortId : (
                      <Box sx={{ cursor: 'pointer' }} onClick={() => openUserCardDrawer(user)}>
                        {shortId}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>{user?.profile?.nickname}</TableCell>
                  <TableCell>{user?.profile?.firstname}</TableCell>
                  <TableCell>{user?.profile?.lastname}</TableCell>
                  <TableCell>
                    {user.profile?.date_registered && dateFormat(user.date_joined)}
                  </TableCell>
                  <TableCell>
                    {user.last_login && dateFormat(user.last_login)}
                  </TableCell>
                  {onSelect && (
                    <TableCell>
                      <IconButton sx={{ color: 'success.main' }} onClick={() => onSelect(user)}>
                        <CheckIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={PER_PAGE_LIST}
        onRowsPerPageChange={onRowsPerPageChange}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        count={count}
        page={page}
        component="div"
      />
      <UserCardDrawer user={activeUser} onClose={closeUserCardDrawer} />
    </>
  )
}

export default UsersTable
