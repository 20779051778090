import React from 'react'
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps
} from '@mui/material'
import { black } from '@src/theme/colors'

export type ConfirmDialogProps = Omit<DialogProps, 'onClose'> & {
  color?: ButtonProps['color'];
  onConfirm?: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
}

const ConfirmDialog = ({
  onCancel,
  onConfirm,
  children,
  color = 'primary',
  okText = 'Ok',
  cancelText = 'Отменить',
  maxWidth = 'xs',
  ...props
}: ConfirmDialogProps) => {
  return (
    <Dialog {...props} onClose={onCancel} maxWidth={maxWidth} fullWidth>
      <DialogContent>
        <DialogContentText sx={{ fontWeight: 500, fontSize: 20, color: black[50] }}>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} sx={{ mr: 1 }}>
          {cancelText}
        </Button>
        <Button color={color} onClick={onConfirm} variant='contained'>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
