import React, { useMemo } from 'react'
import { FormControl, IconButton, InputAdornment, OutlinedInput, OutlinedInputProps } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

export type SearchInputProps = OutlinedInputProps & {
  onClear?: () => void,
  value?: string;
}

const SearchInput = ({ onClear, onChange, value = '', ...props }: SearchInputProps) => {
  const existsSearchValue = useMemo(() => !!(value || '').trim().length, [
    value
  ])

  return (
    <FormControl variant="outlined" fullWidth size="small">
      <OutlinedInput
        { ...props }
        type="text"
        placeholder="Поиск"
        value={value}
        onChange={onChange}
        endAdornment={
          !existsSearchValue ? <></> : (
            <InputAdornment position="end">
              <IconButton onClick={onClear} edge="end">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }
      />
    </FormControl>
  )
}

export default SearchInput
