import React, { ForwardedRef, forwardRef } from 'react'
import FilterChipGroup from '@components/FilterChipGroup'
import FilterChip from '@components/FilterChip'
import { ContentFilterItemListType, ContentFilterType, FilterUserType } from '@src/types/usercontent'
import { BoxProps } from '@mui/material'
import { ContentStatusTitles, ContentTypeTitles } from '@src/types'

export type FilterListType = BoxProps & {
  onFilterChange: (filter: ContentFilterType) => void;
  items: ContentFilterItemListType;
  filter: ContentFilterType;
}

export const userContentFilters: {
  [ type: string ]: {
    [ id: string ]: string;
  }
} = {
  statuses: ContentStatusTitles,
  types: ContentTypeTitles,
  markers: {
    '1': 'Нежелательный контент'
  }
}

const FilterItems = forwardRef(
  ({
    onFilterChange,
    filter,
    items,
    className,
    ...props
  }: FilterListType, forwardedRef: ForwardedRef<HTMLElement>) => {

    return (
      <FilterChipGroup {...props} ref={forwardedRef} className={(className || '') + ' FilterItems'}>
        {items.map(({ type, value }) => {

          switch (type) {
            case 'status__in':
              const statusId = Number(value)
              return (
                <FilterChip
                  key={type + '-' + statusId}
                  label="Статус"
                  value={userContentFilters.statuses[ statusId ]}
                  onDelete={() => {
                    onFilterChange({
                      ...filter,
                      status__in: [ ...(filter?.status__in || []).filter(id => id !== statusId) ]
                    })
                  }} />
              )

            case 'markers__in':
              const markerId = Number(value)
              return (
                <FilterChip
                  key={type + '-' + markerId}
                  label="Маркер"
                  value={userContentFilters.markers[ markerId ]}
                  onDelete={() => {
                    onFilterChange({
                      ...filter,
                      markers__in: [ ...(filter?.markers__in || []).filter(id => id !== markerId) ]
                    })
                  }} />
              )

            case 'user__in':
              const user = value as FilterUserType
              return (
                <FilterChip
                  key={type + '-' + user.id}
                  label="Пользователь"
                  value={user.name}
                  onDelete={() => {
                    onFilterChange({
                      ...filter,
                      user__in: [ ...(filter?.user__in || []).filter(({ id }) => id !== user.id) ]
                    })
                  }} />
              )
          }

        })}
      </FilterChipGroup>
    )
  }
)

export default FilterItems
