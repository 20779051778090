import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import Session from 'supertokens-web-js/recipe/session'
import {
  FetchArgs,
  FetchBaseQueryArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { API_HOST } from '@src/config'

type BaseQueryType = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>;

export function baseQueryConfig(opts?: FetchBaseQueryArgs): BaseQueryType {
  const baseQuery = fetchBaseQuery({
    baseUrl: `${API_HOST}/admin-api/v1`,
    prepareHeaders: (async headers => {
      const accessToken = await Session.getAccessToken()

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`)
      }

      return headers
    }),

    ...opts || {}
  })

  return async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)

    if (result.error
      && [ 401, 403 ].includes(Number(result.error.status))
      && await Session.doesSessionExist()
    ) {
      await Session.attemptRefreshingSession()
      result = await baseQuery(args, api, extraOptions)
    }

    return result
  }
}

export default {
  baseQueryConfig
}
