import React from 'react'
import { Route, Routes } from 'react-router-dom'
import NotFoundPage from '@pages/NotFound'
import LogoutPage from '@pages/Logout'
import { UserStatusTypes } from '@pages/Users'
import { UserContentTypes } from '@src/types'

const HomePage = React.lazy(() => import('@pages/Home'))
const UsersPage = React.lazy(() => import('@pages/Users'))
const UserContentsPage = React.lazy(() => import('@pages/UserContents'))
const CommentsPage = React.lazy(() => import('@pages/Comments'))
const UserInfoPage = React.lazy(() => import('@pages/UserInfo'))
const ContentInfoPage = React.lazy(() => import('@pages/ContentInfo'))
// const VideosPage = React.lazy(() => import('@pages/Videos'))

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="users">
        <Route index element={<UsersPage status={UserStatusTypes.ALL} />} />
        <Route path="new" element={<UsersPage status={UserStatusTypes.NEW} />} />
        <Route path=":userId" element={<UserInfoPage />} />
      </Route>
      <Route path="content">
        <Route index element={<UserContentsPage />} />
        <Route path="posts" element={<UserContentsPage type={UserContentTypes.POST} />} />
        <Route path="videos" element={<UserContentsPage type={UserContentTypes.VIDEO} />} />
        <Route path="streams" element={<UserContentsPage type={UserContentTypes.STREAM} />} />
        <Route path="stories" element={<UserContentsPage type={UserContentTypes.STORIES} />} />
        <Route path="comments" element={<CommentsPage />} />
        <Route path=":contentId" element={<ContentInfoPage />} />
      </Route>
      <Route path="logout" element={<LogoutPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
