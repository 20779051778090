import React, { ReactNode, useEffect } from 'react'
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemProps as MuiListItemProps,
  ListItemText, styled
} from '@mui/material'
import { useMatch, useResolvedPath } from 'react-router-dom'
import ListItem, { ListItemProps } from '@components/Layout/ListItem'
import ExpandMoreIcon from '@components/Layout/ExpandMoreIcon'

export type ListNestedItemProps = MuiListItemProps & {
  label: string;
  icon: ReactNode;
  to: string;
  items: ListItemProps[];
}

const ListNestedItem = styled(
  ({ label, icon, to, items, className }: ListNestedItemProps) => {
    const resolved = useResolvedPath(to)
    const match = useMatch({ path: resolved.pathname, end: false })
    const isActive = !!match
    const [ open, setOpen ] = React.useState(isActive)

    useEffect(() => {
      if (!isActive) {
        setOpen(false)
      }
    }, [ isActive ])

    const classNames = [ className ]
    if(isActive) {
      classNames.push('active')
    }

    return (
      <Box className={classNames.join(' ')}>
        <ListItemButton className={isActive ? 'active' : ''} onClick={() => setOpen(!open)}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label} />
          <ExpandMoreIcon open={open} />
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {items.map((props) => (
              <ListItem {...props} key={props.label} />
            ))}
          </List>
        </Collapse>
      </Box>
    )
  }
)(({ theme }) => ({
  '&.active': {
    backgroundColor: 'rgba(255,255,255,0.1)',
    borderRadius: 10,
    margin: theme.spacing(0, 2),
    padding: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.75),
    '> .MuiButtonBase-root': {
      padding: theme.spacing(0, 1),
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: 'transparent',
      }
    },
    '.MuiCollapse-root': {
      '.MuiListItemButton-root': {
        paddingLeft: theme.spacing(6.625),
        borderRadius: theme.spacing(1),
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, .1)'
        },
      }
    }
  },
  '.MuiCollapse-root': {
    '.MuiListItemButton-root': {
      paddingLeft: theme.spacing(9),
      minHeight: 36
    }
  }
}))

export default ListNestedItem
