import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
  Typography
} from '@mui/material'
import { Fullscreen, FullscreenExit, Pause, Settings } from '@mui/icons-material'
import { PlayIcon } from '@components/icons'
import DropDownPopover from '@components/DropDownPopover'
import { SourceType } from '@components/UserContentCard/UserContentVideo/types'
import { formatVideoTime } from '@utils/datetime'

type VideoControlProps = BoxProps & {
  timeline: number;
  onPlay: () => void;
  onPause: () => void;
  onSeek: (time: number) => void;
  onFullScreen: () => void;
  play: boolean;
  currentSource: number;
  fullScreen: boolean;
  sources: SourceType[];
  onSourceChange: (sourceIndex: number) => void;
  duration: number;
  currentTime: number;
}

const VideoControl = styled(
  ({
    timeline,
    onPlay,
    onPause,
    onFullScreen,
    play,
    fullScreen,
    sources,
    onSourceChange,
    currentSource,
    className,
    duration,
    currentTime,
    onSeek,
    ...props
  }: VideoControlProps) => {
    const [ settingsAnchorEl, setSettingsAnchorEl ] = useState<HTMLElement | null>(null)
    const controlRef = useRef<HTMLDivElement | null>(null)
    const [ show, setShow ] = useState<boolean>(!play)

    useEffect(() => {

      if (!play || !show) return

      const timer = setTimeout(() => {
        setShow(false)
      }, 3000)

      return () => {
        clearTimeout(timer)
      }

    }, [ play, show ])

    const PlayPauseButton = useCallback(({ sx = {}, ...props }: IconButtonProps) => (
      <Box sx={{ width: 40 }}>
        {play ? (
          <IconButton {...props} size="small" onClick={onPause} sx={{ ...sx, ml: 0.5 }}>
            <Pause sx={{ fontSize: 29 }} />
          </IconButton>
        ) : (
          <IconButton {...props} size="small" onClick={onPlay} sx={{ ...sx, ml: 1 }}>
            <PlayIcon size={23} />
          </IconButton>
        )}
      </Box>
    ), [ play ])

    return (
      <>
        <Box
          {...props}
          className={[ className, show ? 'show' : '' ].join(' ')}
          onMouseMove={() => setShow(true)}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => play && setShow(false)}
        >
          <Box
            onClick={(e) => {
              e.stopPropagation()
              play ? onPause() : onPlay()
            }}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <PlayPauseButton sx={{
              background: 'rgba(0, 0, 0, 0.3)',
              padding: 1.5,
              '&:hover': {
                background: 'rgba(0, 0, 0, 0.2)',
              }
            }} />
          </Box>
          <Box ref={controlRef} className="VideoControl">
            <Box className="VideoControl__timeline" onClick={(e) => {
              const elem = e.target as HTMLDivElement
              const { left } = elem.getBoundingClientRect()
              onSeek(!duration ? 0 : (e.pageX - left) / elem.offsetWidth * duration)
            }}>
              <Box sx={{ width: `${timeline}%` }}></Box>
            </Box>
            <Box className="VideoControl__panel">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PlayPauseButton />
                <Typography fontSize={13} fontFamily={'Inter'} sx={{ ml: 1 }}>
                  {formatVideoTime(currentTime)}/{formatVideoTime(duration as number)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton size="small" onClick={onFullScreen} sx={{ px: '1px' }}>
                  {fullScreen ?
                    <FullscreenExit sx={{ fontSize: 31 }} /> :
                    <Fullscreen sx={{ fontSize: 31 }} />}
                </IconButton>
                <IconButton size="small" onClick={(e) => {
                  setSettingsAnchorEl(e.currentTarget)
                }}>
                  <Settings sx={{ fontSize: 26 }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
        <DropDownPopover
          onClose={() => setSettingsAnchorEl(null)}
          container={controlRef.current}
          anchorEl={settingsAnchorEl}
          open={!!settingsAnchorEl}
          alignX="right"
          alignY="top"
        >
          <List>
            {sources.map((source, i) => {
              const active = currentSource === i
              return (
                <ListItem
                  className={active ? 'active' : ''}
                  disablePadding
                  key={i}
                >
                  <ListItemButton disableRipple={active} onClick={() => {
                    !active && onSourceChange(i)
                    setSettingsAnchorEl(null)
                  }}>
                    <ListItemText primary={source.label} />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        </DropDownPopover>
      </>
    )
  }
)(({ theme }) => ({
  transition: theme.transitions.create([ 'opacity' ], {
    duration: 500,
    delay: 200
  }),
  background: 'rgba(0,0,0,0.5)',
  opacity: 0,
  position: 'absolute',
  color: '#FFF',
  inset: 0,
  '&.show': {
    transitionDelay: '50ms',
    opacity: 1,
  },
  '.VideoControl': {
    padding: theme.spacing(0.25, 0),
    position: 'absolute',
    color: '#FFF',
    width: '100%',
    bottom: 0,
    left: 0,
    zIndex: 2,
    '&__timeline': {
      background: 'rgba(255,255,255,0.25)',
      margin: theme.spacing(0, 1.75, 0.75),
      borderRadius: theme.spacing(2),
      overflow: 'hidden',
      '> .MuiBox-root': {
        pointerEvents: 'none',
        background: '#FFF',
        height: '6px',
      }
    },
    '&__panel': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginRight: theme.spacing(1),
      height: 35,
    },

    '.MuiPopover-root': {
      '.MuiPaper-root': {
        background: '#333',
        color: 'white',
        '.MuiList-root': {
          width: 150,
          '.MuiListItem-root.active': {
            background: '#555',
            '&.active .MuiListItemButton-root': {
              cursor: 'default',
            }
          },
          '.MuiListItemButton-root': {
            paddingBottom: 0,
            paddingTop: 0,
          }
        }
      }
    },
  },
  '.MuiIconButton-root': {
    color: '#FFF',
  },
}))

export default VideoControl
