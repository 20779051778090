import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryConfig } from '@utils/api'
import { ListResponseWithPagination, PartialRequestQueryArgs, CommentStatuses, BaseRequestFilters } from '@src/types'
import { CommentModel } from '@src/types/models'

export type ContentCommentFilterType = BaseRequestFilters & {
  usercontent?: string;
  parent?: string;
  user?: string;
}

export type ContentCommentRequestType = PartialRequestQueryArgs & {
  order?: string;
  filter?: ContentCommentFilterType;
}

export const commentsApi = createApi({
  reducerPath: 'commentsApi',
  baseQuery: baseQueryConfig(),
  tagTypes: [ 'Comments' ],
  endpoints: build => ({
    comments: build.query<
      ListResponseWithPagination<CommentModel>,
      ContentCommentRequestType
    >({
      query: ({ page_size = 100, order = 'created', filter, ...params }) => ({
        url: 'comments/',
        params: { ...params, page_size, order, filter: JSON.stringify(filter) },
      }),
      providesTags: (result) =>
        result
          ? [ ...result.results.map(({ id }) => ({ type: 'Comments' as const, id })), 'Comments' ]
          : [ 'Comments' ],
    }),
    changeCommentStatus: build.mutation<CommentModel, {
      status: CommentStatuses;
      commentId: string;
    }>({
      query: ({ commentId, status }) => ({
        method: 'PATCH',
        url: `comments/${commentId}?comment_id=${commentId}`,
        body: {
          status
        }
      }),
      invalidatesTags: (result, error, arg) => (
        [{ type: 'Comments', id: arg.commentId }]
      )
    }),
  })
})

export const {
  useCommentsQuery,
  useChangeCommentStatusMutation,
} = commentsApi
