import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryConfig } from '@utils/api'
import {
  BaseRequestFilters,
  ListResponseWithPagination,
  PartialRequestQueryArgs,
  UserContentStatuses
} from '@src/types'
import { UserContentCounters, UserContentsModel } from '@src/types/models'

export type UserContentFilterType = BaseRequestFilters & {
  type__in?: number[];
  // text?: string;
}

type UserContentRequestQueryArgs = PartialRequestQueryArgs & {
  order?: string;
  filter?: UserContentFilterType;
  include?: string;
}

export const userContentsApi = createApi({
  reducerPath: 'userContentsApi',
  baseQuery: baseQueryConfig(),
  tagTypes: [ 'UserContents' ],
  endpoints: build => ({
    userContents: build.query<
      ListResponseWithPagination<UserContentsModel>,
      UserContentRequestQueryArgs
    >({
      query: ({ page_size = 20, filter, ...params }) => ({
        url: 'usercontent/',
        params: { ...params, page_size, filter: filter && JSON.stringify(filter) }
      }),
      providesTags: (result) =>
        result
          ? [ ...result.results.map(({ id }) => ({ type: 'UserContents' as const, id })), 'UserContents' ]
          : [ 'UserContents' ],
    }),
    userContentInfo: build.query<UserContentsModel | null, string>({
      query: (userContentId: string) => ({
        url: `usercontent/${userContentId}`,
      }),
    }),
    userContentCounters: build.query<{ [ contentId: string ]: UserContentCounters }, { ids: string[] }>({
      query: ({ ids }) => ({
        url: 'counters/usercontent/',
        params: { ids: JSON.stringify(ids) }
      }),
    }),
    changeContentStatus: build.mutation<UserContentsModel, {
      status: UserContentStatuses;
      contentId: string;
    }>({
      query: ({ contentId, status }) => ({
        method: 'PATCH',
        url: `usercontent/${contentId}`,
        body: {
          status
        }
      }),
      invalidatesTags: (result, error, arg) => (
        [{ type: 'UserContents', id: arg.contentId }]
      )
    }),
  })
})

export const {
  useUserContentsQuery,
  useUserContentCountersQuery,
  useChangeContentStatusMutation,
  useUserContentInfoQuery
} = userContentsApi
