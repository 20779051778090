import { PaletteMode, ThemeOptions } from '@mui/material'
import { green, indigo } from '@mui/material/colors'
import { black } from '@src/theme/colors'

const spacing = 8

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => {

  return {
    spacing,
    breakpoints: {
      // values: { xs: 0, sm: 600, md: 900, lg: 1240, xl: 1440 },
    },
    shape: {
      borderRadius: 10
    },
    typography: {
      fontFamily: [
        'Roboto',
        'Inter',
        'Helvetica',
        'Arial',
        'sans-serif'
      ].join(','),
    },
    components: {
      MuiInputBase: {
        styleOverrides: {}
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: black['A100'],
            },
            '&.Mui-focused, &:hover': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: indigo[ 100 ],
              },
            }
          }
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            '.MuiTableCell-root': {
              color: indigo[400],
              fontWeight: 700,
              fontSize: '0.85rem',
              borderBottomColor: indigo[ 100 ],
            }
          }
        }
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            '&.Mui-active': {
              color: indigo[400],
              '.MuiTableSortLabel-icon': {
                color: indigo[400],
              }
            },
          }
        }
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            '.MuiTableCell-root': {
              borderBottomColor: indigo[ 50 ],
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: '1rem'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: indigo[50],
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
          sizeMedium: {
            fontSize: 14,
          },
          sizeSmall: {
            fontSize: 12,
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: spacing * 2,
            paddingTop: spacing
          }
        }
      }
    },
    palette: {
      mode,
      ...(
        mode === 'light' ? {
          primary: indigo,
          secondary: green,
        } : {}
      )
    }
  }
}
