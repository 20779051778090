import React, { useMemo } from 'react'
import { black } from '@src/theme/colors'
import { Box, BoxProps, CircularProgress, styled, Typography } from '@mui/material'
import { DislikeOutlineIcon, FollowIcon, LikeOutlineIcon } from '@components/icons'
import { counterFormat } from '@utils/number'
import { useUserContentCountersQuery } from '@api/usercontents'

export type ContentItemCountersType = BoxProps & {
  userContentId: string;
}

const ContentItemCounters = styled(({ userContentId, ...props }: ContentItemCountersType) => {

  const { data, isFetching } = useUserContentCountersQuery({
    ids: [ userContentId ]
  })

  const counter = useMemo(() => {
    if (!isFetching && data && data[ userContentId ]) {
      return data[ userContentId ]
    }
  }, [ isFetching, data ])

  return (
    <Box { ...props }>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 70 }}>
          <LikeOutlineIcon />
          <Typography sx={{ ml: 1 }}>
            {counter ? counterFormat(counter.content_like_like) : (
              isFetching ? <CircularProgress size={16} color='inherit' /> : '-'
            )}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DislikeOutlineIcon />
          <Typography sx={{ ml: 1 }}>
            {counter ? counterFormat(counter.content_like_dislike) : (
              isFetching ? <CircularProgress size={16} color='inherit' /> : '-'
            )}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ mr: 1.25 }}>? ?</Typography>
        <FollowIcon sx={{ position: 'relative', top: -2 }} />
      </Box>
    </Box>
  )
})(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  color: black[300],
  display: 'flex',
  '.MuiCircularProgress-root': {
    margin: 0
  },
  svg: {
    fontSize: 24,
    path: {
      fill: black[200]
    }
  }
}))

export default ContentItemCounters
