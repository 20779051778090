import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { userApi } from '@api/user'
import { userContentsApi } from '@api/usercontents'
import { adminUserApi } from '@api/admin_user'
import { commentsApi } from '@api/comments'

export const store = configureStore({
  reducer: {
    [ userApi.reducerPath ]: userApi.reducer,
    [ adminUserApi.reducerPath ]: adminUserApi.reducer,
    [ userContentsApi.reducerPath ]: userContentsApi.reducer,
    [ commentsApi.reducerPath ]: commentsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      adminUserApi.middleware,
      userContentsApi.middleware,
      commentsApi.middleware,
    )
})

setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store
