import React from 'react'
import { Box, BoxProps, styled } from '@mui/material'

const CardDrawerBody = styled(({ className, ...props }: BoxProps) => (
  <Box { ...props } className={className + ' CardDrawer__Body'} />
))(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'auto',
}))

export default CardDrawerBody
