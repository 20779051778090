import React from 'react'
import { Box, BoxProps, Button, styled } from '@mui/material'
import { RedoIcon } from '@components/icons'

export type PageTitleRowProps = BoxProps & {
  existsFilters?: boolean;
  onResetFilters?: () => void;
}

const PageTitleRow = styled(({ children, existsFilters, onResetFilters, ...props }: PageTitleRowProps) => {
  return (
    <Box {...props}>
      {children}
      {existsFilters && (
        <Button onClick={onResetFilters}>
          <RedoIcon />
          Сбросить
        </Button>
      )}
    </Box>
  )
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  '.MuiButton-root': {
    textTransform: 'none',
    fontSize: '14px',
    svg: {
      marginRight: theme.spacing(0.5)
    }
  }
}))

export default PageTitleRow
