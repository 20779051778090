import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/material'
import InlineButton, { InlineButtonProps } from '@components/InlineButton'

const AddFilterButton = styled(
  ({ className, ...props }: InlineButtonProps) => {
    return (
      <>
        <InlineButton {...props} className={className + ' AddFilterButton'}>
          <AddIcon /> Фильтр
        </InlineButton>
      </>
    )
  }
)(({ theme: { spacing }}) => ({
  fontSize: 14,
  '.MuiSvgIcon-root': {
    marginRight: spacing(0.4),
    height: 18,
    width: 18,
  }
}))

export default AddFilterButton
