import MuiExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { styled } from '@mui/material'

const ExpandMoreIcon = styled(MuiExpandMoreIcon, {
  shouldForwardProp: propName => propName !== 'open'
})<{open?: boolean}>(({ theme: { transitions }, open }) => ({
  transition: transitions.create('transform'),
  transform: `rotate(${open ? 0 : -90}deg)`,
  height: 25,
  width: 25,
}))

export default ExpandMoreIcon
