import React from 'react'
import { Box, BoxProps, styled, Typography } from '@mui/material'

const AccessDenied = styled((props: BoxProps) => {
  return (
    <Box {...props}>
      <Typography>У Вас не достаточно прав для просмотра данного раздела</Typography>
    </Box>
  )
})(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: 'calc(100vh - 200px)',
  alignItems: 'center',
  justifyContent: 'center',
  '.MuiTypography-root': {
    color: theme.palette.error.main,
    fontSize: '21px',
  }
}))

export default AccessDenied
