import React from 'react'
import { Box, IconButton, styled, Toolbar, ToolbarProps, Typography } from '@mui/material'
import { CloseIcon, MoreIcon } from '@components/icons'

type CardDrawerToolbarProps = ToolbarProps & {
  onClose?: () => void;
  onMore?: () => void;
}

const CardDrawerToolbar = styled(
  ({ className, children, onClose, onMore, ...props }: CardDrawerToolbarProps) => (
    <Toolbar { ...props } className={className + ' CardDrawer__Toolbar'}>
      <Typography fontSize={14} fontWeight={700}>
        {children}
      </Typography>
      <Box>
        <IconButton onClick={onMore}>
          <MoreIcon />
        </IconButton>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Toolbar>
  )
)(({ theme }) => ({
  justifyContent: 'space-between',
  paddingRight: theme.spacing(2),
}))

export default CardDrawerToolbar
