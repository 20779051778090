import { UserProfile } from '@src/types/models'

export function debug(...args: any[]) {
  if(process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line
    console.log(...args)
  }
}

export function debounce(delay: number, fn: Function) {
  let timer: NodeJS.Timeout
  return function (...args: []) {
    timer && clearTimeout(timer)
    timer = setTimeout(() => fn(...args), delay)
  }
}

export function getUserName(profile: UserProfile | undefined | null): string {
  if(profile?.firstname || profile?.lastname) {
    return `${profile.firstname} ${profile.lastname}`
  }

  return 'Anonymous'
}
