import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Session from 'supertokens-web-js/recipe/session'
import Loading from '@components/Loading'

const LogoutPagePage = () => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      if(await Session.doesSessionExist()) {
        setLoading(true)
        await Session.signOut()
        setLoading(false)
      }
      navigate('/auth')
    })()
  }, [])

  return loading ? <Loading /> :  <></>
}

export default LogoutPagePage
